import {Component,props} from "react";
import './time.css'

class AnsMin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name : this.props.name,
      value: ""
    }
  }

  getName = () =>{
    return this.name;
  } 

  onChange = (e) => {
    const re = /^[0-5]?\d?$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       this.setState({value: e.target.value})
    }
  }


  render(){
     var {name} = this.props; 
     return (
        <input name={name}  value={this.state.value}
          className={"Block Color" + name} placeholder="?" onChange={(e) => this.onChange(e)} />
              
    );
  }
}

export default AnsMin;