import React, { Component } from 'react';
import Clock from '../Components/Times/Clock';
import ReactDOM from 'react-dom/client';
import {Row,Col,Button} from 'react-bootstrap';

import correct from '../images/correct.png';
import wrong from '../images/wrong.png';
import { oConstants } from '../Components/Times/oconstant';
import { playEffect } from '../Components/Maths/common/mSound';

import anime from 'animejs';

class TimeGame extends Component {
        constructor(props) {
        super(props);
        this.state = {
        }
        this.setQ = this.genRandomTimes()
    }

    randomTime = () => {
        var hour = 0 + Math.random() * (23 - 0) | 0;
        var min = 0 + Math.random() * (59 - 0) | 0;
        return [hour,min];
    }

    genRandomTimes = () =>{
        var times = []
        for (var i= 0; i < 4; i ++) {
            times.push(this.randomTime())    
        }
        return times;
    }

    buildQ(mkey,grade,time) {
        var comQ = this.buildClockQ(time)    
        return( <div key={mkey} className={"numBlock m"+ mkey} ans={time}>
             {comQ}   
        </div>);
    }

    buildClockQ = (time) =>{
        return <Clock  date={time} />       
    }

    buildPractice(){
        let grade = 11;
        let sgrade = grade.toString()

        let digit = 1;
        if(grade == 11 || grade == 23) {
                digit = 2
        }
        if(grade == 21 || grade == 32 ) {
                digit = 3
        }
        if(grade == 31 || grade == 33) {
                digit = 4
        }
        grade = parseInt(grade)
        this.mgrade =  grade;
        var numQ = 4;
        
        var md = 3
        var lg = 3
        var sm = 12
        
        if(digit == 2){
            md = 4
            lg = 4
            sm = 12
        }

        if(digit > 2){
            md = 4
            lg = 4
            sm = 12
        }
        if(digit > 3){
            md = 6
            lg = 6
        }

        var blockWidth4 = 320
        var blockWidth3 = 300
        var blockWidth2 = 240
        var blockWidth1 = 200

        var nrow = 1;
        var ncol = numQ/nrow;
        var numQRow = 4;
        var winWidth = window.innerWidth;
        console.log(winWidth)
        if(winWidth >0){
            numQRow = Math.min(Math.floor(winWidth/blockWidth4),4);
            nrow = 1;
            ncol = numQRow;
            numQ = ncol 
        
            md = Math.floor(12/ncol)
            lg = Math.floor(12/ncol)
            sm = Math.floor(12/ncol)
        }
        
        var rows =[];
        
        if(numQ < 4){
            this.counterQ = numQ
            var i = (4 - this.setQ.length)+numQRow;
            i = Math.min(i,4)    
            if(i < 4){
                rows.push(<Button id="nxt" 
                style={{fontFamily:"fantasy",fontSize:"15px"}} 
                onClick={(e) => this.nextQ(e)}>{i} of {4} &gt; &gt;
                        </Button>)
           }else{
                rows.push(<Button id="nxt" 
                style={{fontFamily:"fantasy",fontSize:"15px"}} 
                onClick={(e) => this.nextQ(e)}>{i} of {this.totalQ} 
                        </Button>)

           }
        }
        rows.push(<br/>)
        rows.push(<br/>)
        
        var qCount = 0;
        var mkey =0; 
        for(var i=0; i< nrow;i++){
             var cols =[];
             for(var j=0; j< ncol;j++){
                if(qCount == numQ) break;
                mkey++;
                qCount++;
                var compQ = this.setQ.shift(); //this.setQ[qCount-1];
                var clk = this.buildQ(mkey,grade,compQ);        
                this.counterQ++;
                var clname = "text-center col-xs-12 col-sm-" +sm+" col-md-" + md +" col-lg-"+lg;
                cols.push(<Col key={mkey} 
                        className={clname}>
                        {clk}</Col>)
            }
            
            rows.push(<Row key={mkey} className="text-center"> {cols}</Row>);
            
        

            
            rows.push(<br/>)
            if(qCount == numQ) break;
        }    
        
       
        return rows;

    }

    nextQ = (e) => {
        oConstants.tried = Object.keys(oConstants.result).length
        console.log(oConstants)
        if(this.setQ.length+oConstants.tried < 4){
             this.setMyFocus()
             return;    
        }

        
        var sButton = document.getElementById("sButton");
        var vButton = document.getElementById("vButton");

        sButton.style["display"] = "block";
        vButton.style["display"] = "block";

        var mcqDiv = document.getElementById("MCQDiv");
                
        const mcQ = ReactDOM.createRoot(
            mcqDiv
        );
        var nQs = this.buildPractice();
        mcQ.render(nQs);
        this.counterQ++

    
    } 

    getQA = (div) => {
        var anss = div.getAttribute("ans");
        anss = anss.split(",")

        var hr = parseInt(anss[0]);
        hr = hr>=12?hr-12:hr;
        hr = hr==0?12:hr;
        
        var min = anss[1];
        if(min.length < 2) {
            min = "0" + min;
        }
        
        var inputs = div.querySelectorAll('input');

        var hrInput = parseInt(inputs[0].value)
        var minInput = parseInt(inputs[1].value)

        var Q = hr + ":" + min
        var A = hrInput + ":" + minInput
         return {"Qs":Q, "As": A};
    }

    isCorrect = (Qs,As) => {
        if(As.includes("NaN")){
            return -1
        }
        if(Qs === As) {
            return 1
        }
        return 0
    }

    setMyFocus = (div) =>{
        var adivs = div.querySelectorAll('input.Block');
        var mInputs = Array.from(adivs)
        mInputs.forEach( i => {
            i.style["border-color"] = "red"
            i.focus();
        })
        

    } 

    verify = () =>{
        var qDIVs = document.getElementsByClassName("numBlock");
        //var qDIVs = document.querySelectorAll('input');
        
        qDIVs = Array.from(qDIVs).filter(div => {
            var style = div.getAttribute("style");
            if(style) {
                return !style.includes("opacity");
            }
            
            return true;
        }); 

     
        var imgMarks = document.getElementsByClassName("mark");

        var imgRight = imgMarks[0].childNodes[0]
        var imgWrong = imgMarks[1].childNodes[0]
        
        var count =0;
        
        Array.from(qDIVs).forEach( div => {
            var {Qs,As} = this.getQA(div);
            var isCorrect = this.isCorrect(Qs,As);
            var className = div.getAttribute("class");
            className = className.slice(-3).trim()
            if(isCorrect==1){
                div.style["border-color"] = "green";
                var img = imgRight.cloneNode(true)
                img.style["position"] = "relative"
                img.style["z-index"] = "20"
                img.style["margin-top"] = "-300px"
                
                img.style["width"] = "80px"
                
                div.firstChild.appendChild(img)
                div.style["disabled"] = true
                div.style["opacity"] = "0.6"
                div.style["pointer-events"] = "none"
                oConstants.result[className] = true
                count++;
            }else if(isCorrect==0){
                div.style["border-color"] = "red";
                var img = imgWrong.cloneNode(true)
                img.style["position"] = "relative"
                img.style["z-index"] = "20"
                img.style["margin-top"] = "-300px"
                
                img.style["width"] = "80px"
                
                div.firstChild.appendChild(img)
                div.style["disabled"] = true
                div.style["opacity"] = "0.6"
                div.style["pointer-events"] = "none"
                oConstants.result[className] = false
            }else if(isCorrect==-1){
                prompt++;
            }
        }) ;    

    
        oConstants.tried = Object.keys(oConstants.result).length

        console.log(oConstants)
  
        var correct =0
        if(oConstants.tried) {
            Object.keys(oConstants.result).forEach((k) => {
                if(oConstants.result[k]){
                    correct++;
                }
            })
        }

        var scoreCard = document.getElementsByClassName("scoreCard");
        scoreCard[0].textContent = correct  + " of " + 4;
     
        
        if(oConstants.tried==0){
            playEffect("ifnull")
            this.setMyFocus(qDIVs[0])
        }


        if(oConstants.tried == 4) {
            this.setState({resultshow: true})
        }
 
    }

    componentDidMount() {
        if(!this.initState) {
            oConstants.result = {}
            var winWidth = window.innerWidth;
            if(winWidth> 0) { 
                this.initState = true;
                var mcqDiv = document.getElementById("MCQDiv");
                
                const mcQ = ReactDOM.createRoot(
                    mcqDiv
                );
                var nQs = this.buildPractice();
                mcQ.render(nQs);
                this.counterQ++
            }
        }

    }

    animateAnswer(div) {

        var anss = div.getAttribute("ans");
        anss = anss.split(",")

        var hr = parseInt(anss[0]);
        hr = hr>=12?hr-12:hr;
        hr = hr==0?12:hr;
        
        
        var min = anss[1];
        if(min.length < 2) {
            min = "0" + min;
        }
        
        var inputs = div.querySelectorAll('input');

       var hrInput = inputs[0]
        var minInput = inputs[1]

        var tl = anime.timeline({
            easing: 'easeInOutCirc',
            autoplay:false,
            complete: (anim) => {
                
            }
        });


        tl = tl.add({
            targets: hrInput,
            duration: 500,
            easing: 'linear',
            value:hr,
            round: 1,
            opacity: ['25%','50%','100%'],
            offset:0,
            complete : (anime) =>{
                var tables = anime.animatables;
                var d = tables.map(a => a.target);
                var value = d[0].value
                
                if(value.toString().length > 1) {
                    value = value.slice(-1)
                }

                d[0].setAttribute("class","Block Color5");
                d[0].setAttribute("disabled","true")

            }});
        
       
        tl = tl.add({
            targets: minInput,
            duration: 500,
            easing: 'linear',
            value:min,
            opacity: ['25%','50%','100%'],
            offset:1500,
            round: 1,
            complete : (anime) =>{
                var tables = anime.animatables;
                var d = tables.map(a => a.target);
                var value = parseInt(d[0].value)
                var sValue = value.toString().trim()
                if(sValue.length < 2) {
                    d[0].value = "0" + sValue;
                }
                 
                if(sValue.length > 1) {
                    value = sValue.slice(-1)
                }

                d[0].setAttribute("class","Block Color2");
                d[0].setAttribute("disabled","true")
            }});
       
        tl.play();
     

    }

    showAnswers = () => {
        var qDIVs = document.getElementsByClassName("numBlock");

        Array.from(qDIVs).forEach( div => {
            this.animateAnswer(div)
            var className = div.getAttribute("class");
            className = className.slice(-3).trim()
            oConstants.result[className] = false;
           
        }) ;
        var sButton = document.getElementById("sButton");
        var vButton = document.getElementById("vButton");

        sButton.style["display"] = "none";
        vButton.style["display"] = "none";

        oConstants.tried = Object.keys(oConstants.result).length
        if(oConstants.tried == 4) {
            setTimeout(() => {
                this.setState({resultshow: true})
            },3000);
        } 
    }


    render() { 
        return (<div  className="container">
        <div id="MCQDiv"/>
        <div className="btnDiv biggerText"  style={{marginRight:"10%",fontFamily:"fantasy",fontSize:"22px"}} >
                    <Row className="text-center">
                        <Col>
                            <Button onClick={this.verify} id="vButton"> 
                                Check
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={this.showAnswers} id="sButton" > 
                                Answers
                            </Button>
                        </Col>
                        <Col className="scoreCard" 
                            style={{fontFamily:"fantasy",fontSize:"30px"}}>
                                0/4
                        </Col>
                    </Row>
                </div>
                <div className="mark right" style={{width:"1px"}}> 
                    <img style={{width:"100%"}} src={correct}/>
                    </div>
                <div className="mark wrong" style={{width:"1px"}}>
                    <img style={{width:"100%"}} src={wrong}/>
                    </div>
        </div>);
    }
}
 
export default TimeGame;