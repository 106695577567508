import React, { Component } from 'react';
import './clock.css';
import anime from 'animejs/lib/anime.es.js';
import AnsHr from './AnsHr';
import AnsMin from './AnsMin';
import {Row,Col,Button} from 'react-bootstrap';

class Clock extends Component {
    constructor(props){
        super(props)
        this.state = {
            time: this.props.date,
            init:false
        };
        this.mid = this.props.id;
        this.sRef = React.createRef();
        this.mRef = React.createRef();
        this.hRef = React.createRef();    
    }
    
    componentDidMount() {
        if(!this.state.init){
            this.setMyTime(); 
            // setInterval(() => {
            //     this.addSecond();  
            //     this.addMinute();   
            //     this.addHour();                    
            // }, 10);
            this.setState((prevState,props)=> {
                prevState.init = true;
            })
        }
    }

   

    addHour() {
        var hourHand = this.hRef.current 
        anime({
            targets: hourHand,
            duration: 10,
            easing: 'linear',
            rotate: {
                value: '+= 0.000084'
            },
            complete : (anime) =>{
                
            }}
        );
    }

    addMinute() {
        var minHand = this.mRef.current 
        anime({
            targets: minHand,
            duration: 0,
            easing: 'linear',
            rotate: {
                value: '+=0.001'
            },
            complete : (anime) =>{
                
            }}
        );
    }

    addSecond() {
        var minSecond = this.sRef.current
        anime({
            targets: minSecond,
            duration: 0,
            easing: 'linear',
            rotate: {
                value: '+=0.06'
            },
            complete : (anime) =>{
                
            }}
        );
    }


    runSecond() {
        var minSecond = document.getElementById("second") 
        anime({
            targets: minSecond,
            duration: 250,
            easing: 'linear',
            rotate: {
                value: '+=20'
            },
            complete : (anime) =>{
                
            }}
        );
    }
    runMinute() {
        var minHand = document.getElementById("minute") 

        anime({
            targets: minHand,
            duration: 10,
            easing: 'linear',
            rotate: {
                value: '+=1'
            },
            complete : (anime) =>{
                
            }}
        );
    }

    runHour() {

        var hourHand = document.getElementById("hour") 

        anime({
            targets: hourHand,
            duration: 250,
            easing: 'linear',
            rotate: {
                value: '+=20'
            },
            complete : (anime) =>{
            }}
        );
    }

    setMyTime() {
        var mtime = this.state.time;
        
        var isSecond = true
        if(mtime.length < 3) {
            isSecond = false    
        }
       
        var hr = mtime[0];
        hr  = (hr > 12)?hr-12:hr;
        var min = mtime[1];
        //var sec = this.state.time.getSeconds();
        
        
        var secHand = this.sRef.current
        secHand.style["display"] = "none";
        //var deltaSec = sec*6;
        //secHand.setAttribute("style", "transform: rotate(" + (deltaSec-90) + "deg)");

        var minHand = this.mRef.current 
        var deltaMin = min*6+0/2;
        minHand.setAttribute("style", "transform: rotate(" + (deltaMin-90) + "deg)");

        var hourHand = this.hRef.current 
        var deltaHr = hr*30+min/2;
        hourHand.setAttribute("style", "transform: rotate(" + (deltaHr-90) + "deg)");
    }

    setMyTime2() {
        var hr = this.state.time.getHours();
        hr  = (hr > 12)?hr-12:hr;
        var min = this.state.time.getMinutes();
        var sec = this.state.time.getSeconds();
        
        
        var secHand = this.sRef.current
        var deltaSec = sec*6;
        secHand.setAttribute("style", "transform: rotate(" + (deltaSec-90) + "deg)");

        var minHand = this.mRef.current 
        var deltaMin = min*6+sec/2;
        minHand.setAttribute("style", "transform: rotate(" + (deltaMin-90) + "deg)");

        var hourHand = this.hRef.current 
        var deltaHr = hr*30+min/2;
        hourHand.setAttribute("style", "transform: rotate(" + (deltaHr-90) + "deg)");
    }

    render() {
        return <div className="container">
            
            <div className="circle">
                <div className="second" ref={this.sRef} id="second"></div>
                <div className="minute" ref={this.mRef} id="minute"></div>
                <div className="hour" ref={this.hRef} id="hour"></div>
                <div className="dot" />
                      
                {/* <div className="ticks">
                   {this.allticks()}
                </div> */}
            </div>
            <br/><br/>
            <div style={{fontFamily:"fantasy",fontSize:"28px"}}>
                <Row> <Col> <AnsHr/> : <AnsMin/></Col></Row>    
            </div>    

            {/* <Button variant="outline-primary" onClick={this.runMinute}>Primary</Button>
            <Button variant="outline-primary" onClick={this.runHour}>Primary</Button>
             */}
            </div>
    }
}
 
export default Clock;
